import { Link, NavLink, useLocation } from "@remix-run/react";
import { useState, useEffect } from "react";
import { classNames } from "~/lib/util";
import { useRdt } from "~/lib/rdt/hooks/useRdt";
import HeaderMobile from "./headerMobile";

declare global {
  namespace JSX {
    interface IntrinsicElements {
      "radix-connect-button": React.DetailedHTMLProps<
        React.HTMLAttributes<HTMLElement>,
        HTMLElement
      >;
    }
  }
}

export default function Header() {
  const [menuOpen, setMenuOpen] = useState(false);
  const location = useLocation();
  const rdt = useRdt();

  // set button layout
  useEffect(() => {
    rdt?.buttonApi.setTheme("white");
    rdt?.buttonApi.setMode("light");
  }, []);

  function toggleMenu() {
    setMenuOpen(!menuOpen);
  }

  function closeMenu() {
    if (!menuOpen) {
      return;
    }

    setMenuOpen(false);
  }

  return (
    <header className="sticky top-0 z-50 backdrop-blur-sm">
      <nav className="px-4 bangers pt-6 pb-3 xl:container xl:mx-auto xl:px-0 flex flex-wrap items-center justify-start">
        <ul className="text-[24px] hidden md:flex bg-[#3e9a96] py-1.5 gap-4 rounded-full px-4 flex-row justify-start text-white">
          <li>
            <NavLink
              to="/"
              className={({ isActive }) =>
                (isActive ? "border-white-800" : "border-none") +
                " whitespace-nowrap mx-4 pt-0.5 border-b-2 hover:border-white-800"
              }
            >
              Receive HUGs
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/staking"
              className={({ isActive }) =>
                (isActive ? "border-white-800" : "border-transparent") +
                " whitespace-nowrap mx-4 pt-0.5 border-b-2 hover:border-white-800"
              }
            >
              Stake HUGs
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/leaderboard"
              className={({ isActive }) =>
                (isActive ? "border-white-800" : "border-transparent") +
                " whitespace-nowrap mx-4 pt-0.5 border-b-2 hover:border-white-800"
              }
            >
              Leaderboard
            </NavLink>
          </li>
        </ul>

        <HeaderMobile />

        <div className="ml-auto flex items-center gap-4 justify-end">
          <button
            style={{
              fontSize: "26px",
              background: "linear-gradient(#FF0100, #D00707)",
            }}
            onClick={() => {
              window.open(
                "https://astrolescent.com/?to=resource_rdx1t5kmyj54jt85malva7fxdrnpvgfgs623yt7ywdaval25vrdlmnwe97"
              );
            }}
            className=" rounded-full bangers justify-end text-xl font-semibold py-2 px-6 text-white"
          >
            Buy $HUG
          </button>
          <radix-connect-button />
        </div>
      </nav>
    </header>
  );
}
